<template>
  <div class="login">
    <div class="form">
      <!-- TODO 需要给这个替换成图片文字 -->
      <p class="login-title">Login</p>
      <div class="box">
        <el-input v-model="loginInfo.phone" placeholder="Phone Number">
          <i slot="prefix" class="el-icon-phone input-icon" />
        </el-input>
      </div>
      <div class="box">
        <el-input v-model="loginInfo.password" type="password" placeholder="password">
          <i slot="prefix" class="el-icon-lock input-icon" />
        </el-input>
      </div>
      <div class="login-actions">
        <button class="login-button" @click="userLogin">Sign in</button>
        &nbsp;
        <button class="login-button" @click="sw">Sign up</button>
      </div>
    </div>
  </div>
</template>

<script>
import { getRandomNumCanvas } from "@/utils";
import axios from "axios";
import { throttle } from "lodash";
export default {
  data() {
    return {
      loginyzm: "",
      inputVue: "",
      login: false,
      input: "",
      loginInfo: {
        password: "",
        phone: "",
      },

      allTexts: "ABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890", // 字符串
      width: 120,
      height: 40,
      imgCode: "",
      imhCode1: "",
    };
  },
  watch: {
    login(newValue, oldValue) {
      console.log(1111);
      this.imgCode = this.draw();
    },
  },
  mounted() {
    // 初始化绘制图片验证码
    this.imgCode = this.draw();
  },
  methods: {
    // 需要节流的方法
    handleClick: throttle(function () {
      // 处理点击事件
    }, 1000), // 1000毫秒的节流

    sw() {
      this.$router.push("/register");
    },

    userLogin: throttle(function () {
      if (this.loginInfo.phone == "" || this.loginInfo.password == "") {
        this.$message.error("phone or password is null");
        return;
      }
      axios
        .post("/puserSys/puser/login", this.loginInfo, {
          withCredentials: true,
        })
        .then((res) => {
          // console.log("userLogin", res.data);
          sessionStorage.setItem("tokenUrl", "");
          if (res.data.data) {
            sessionStorage.setItem("token", res.data.data.userid);
            sessionStorage.setItem("tokenUrl", res.data.data.Token);
            sessionStorage.setItem(
              "loginInfopassword",
              this.loginInfo.password
            );
            sessionStorage.setItem("phone", this.loginInfo.phone);
            this.$message.success("Login success");
            this.$router.push("/");
          } else {
            this.$message.error("Account or Password Error or captcha error");
          }
        })
        .catch(() => { });
    }, 3000),
    logon: throttle(function () {
      if (this.logonInfo.phone == "" || this.logonInfo.password == "") {
        this.$message.error("password or phone or captcha is null");
        return;
      }
      if (this.logonInfo.phone.length < 3 || this.logonInfo.phone.length > 30) {
        this.$message.error("phone length is 1 to 30 digits");
        return;
      }

      if (
        this.logonInfo.password.length < 6 ||
        this.logonInfo.password.length > 10
      ) {
        this.$message.error("Password length is 6 to 10 digits");
        return;
      }
      //  console.log(this.logonInfo);
      axios
        .post("/puserSys/puser/logon", this.logonInfo)
        .then((res) => {
          console.log(res);
          if ((res.data.msg = "Logon Success")) {
            this.$message.success("Registration successful, please log in");
            this.login = !this.login;
            //console.log(res);
            return;
          }

          if ((res.data.msg = "User Already Exists")) {
            this.$message.error("User Already Exists");
            return;
          }
        })
        .catch(() => { });
    }, 3000),

    //todo 点击图片刷新
    handleDraw() {
      this.imgCode = this.draw();
    },
    handleDraw() {
      this.imgCode1 = this.draw();
    },
    draw() {
      const canvas = document.getElementById("canvas");
      this.loginyzm = getRandomNumCanvas({
        canvas,
        allTexts: this.allTexts,
        width: this.width,
        height: this.height,
      });
    },
  },
};
</script>

<style scoped>
.yz {
  display: flex;
}

.login-actions {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.login-button {
  padding: 10px 15px;
  font-size: 20px;
  border: none;
  border-radius: 5px;
  /* TODO 需要替换这里的背景图片，并设置宽高 */
  background-color: #903c35;
  color: #fff;
  box-shadow: 8px 9px 14px #903c35;
  font-weight: bold;
  font-size: 24px;
}

.login {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: url("/public/login.jpeg");
  background-size: cover;
}

.login-title {
  font-size: 50px;
  margin: 0;
  font-weight: 600;
  text-shadow: 1px 2px 10px #903c35;
}

.yzm {
  display: flex;
  justify-content: space-between;
}

.yz .el-input {
  width: 90%;
}

:deep(.el-input) {
  border-radius: 8px;
  border: 4px solid #903c35;
  box-shadow: 1px 2px 10px #903c35;
}

:deep(.el-input .el-input__inner) {
  font-size: 20px;
  font-weight: bold;
  padding-left: 45px !important;
  border: none !important;
}

:deep(.el-input__inner::-webkit-input-placeholder) {
  font-size: 15px;
  font-weight: bold;
}

:deep(.el-input__prefix) {
  display: flex;
  width: 40px;
  align-items: center;
  justify-content: center;
}

.input-icon {
  font-size: 20px;
  padding: 4px;
  font-weight: bold;
  background-color: #903c35;
  color: white;
  border-radius: 100%;
}

.img-canvas {
  height: 40px;
  border-radius: 8px;
  border: 4px solid #903c35;
  box-shadow: 1px 2px 10px #903c35;
}

#canvas {
  border-radius: 4px;
}

.form {
  width: 35%;
  height: 80%;
  background-color: #fffefc80;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  padding: 0 35px 0 35px;
  box-shadow: 1px 0px 4px;
  border-radius: 10px;
  position: relative;
}

.box {
  display: flex;
  justify-content: center;
}

.form1 {
  width: 35%;
  height: 60%;
  background-color: rgb(255, 254, 252);
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  padding: 0 35px 0 35px;
  box-shadow: 1px 2px 10px;
  border-radius: 10px;
  position: relative;
}

/* .phone{

} */

.img1 {
  display: block;
}

@media screen and (max-width: 1000px) {
  .form {
    width: 70%;
    height: 70%;
  }
}

@media screen and (max-height: 1200px) {
  .form {
    width: 70%;
    height: 35%;
  }
}
</style>
