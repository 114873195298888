const randomNum = (min, max) => {
  return parseInt(Math.random() * (max - min) + min);
};
//todo 产生随机颜色
const randomColor = (min, max) => {
  const r = randomNum(min, max);
  const g = randomNum(min, max);
  const b = randomNum(min, max);
  return `rgb(${r},${g},${b})`;
};

export const getRandomNumCanvas = ({
  canvas,
  allTexts,
  width,
  height,
  length = 4,
}) => {
  const ctx = canvas.getContext("2d");
  //!! 1.填充背景颜色，背景颜色要浅一点
  ctx.fillStyle = "#ffffff";
  // 填充的位置
  ctx.fillRect(0, 0, width, height);
  let imgCode = "";
  //!! 2.随机产生字符串，并且随机旋转
  for (let i = 0; i < length; i++) {
    // 随机的四个字
    const text = allTexts[randomNum(0, allTexts.length)];
    imgCode += text;
    // 随机的字体大小
    const fontSize = 24;
    // 字体随机的旋转角度
    const deg = randomNum(-5, 30);
    ctx.font = fontSize + "px Arial";
    ctx.textBaseline = "middle";
    ctx.fillStyle = randomColor(80, 150);
    ctx.save();
    ctx.translate(24 * i + 15, height / 2);
    ctx.rotate((deg * Math.PI) / 180);
    ctx.fillText(text, 0, 0);
    ctx.restore();
  }
  // !! 3.随机产生5条干扰线
  for (let i = 0; i < 5; i++) {
    ctx.beginPath();
    ctx.moveTo(randomNum(0, width), randomNum(0, height));
    ctx.lineTo(randomNum(0, width), randomNum(0, height));
    ctx.strokeStyle = randomColor(180, 230);
    ctx.closePath();
    ctx.stroke();
  }

  // !! 4.随机产生40个干扰的小点
  for (let i = 0; i < 40; i++) {
    ctx.beginPath();
    ctx.arc(randomNum(0, width), randomNum(0, height), 1, 0, 2 * Math.PI);
    ctx.closePath();
    ctx.fillStyle = randomColor(150, 200);
    ctx.fill();
  }

  return imgCode;
};
